export default {
    get: (name) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    },

    has: (name) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.has(name);
    }
}